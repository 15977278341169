import { useRef } from 'react';
import { useRouter } from 'next/router';
import { replaceParentWindowLocation } from '@utils';
import { useClientMessage, useUser } from '@modules/_app';

export const DEFAULT_REDIRECT_URL = '/';
export const CATALOG_URL = '/catalog';

export function useOnAuth() {
  const { refetchUser } = useUser();
  const { replace, query } = useRouter();
  const clientMessage = useClientMessage();
  const signUpRef = useRef<boolean | null>(null);
  const isAuthFromDemo = query.demo === 'true';
  const isAuthFromJoinWorkspace = query.joinWorkspace === 'true';
  const isAuthFromJoinProject = query.joinProject === 'true';
  const isAuthAfterCommunitySSO = !!query.communitySSOBackURL;

  const redirectURL =
    (query.redirectURL as string | undefined) ?? DEFAULT_REDIRECT_URL;

  const derivedReplaceTopWindow =
    typeof query.replaceParentWindow === 'string'
      ? query.replaceParentWindow.toLowerCase()
      : undefined;
  const safeReplaceTopWindow =
    derivedReplaceTopWindow &&
    ['top', 'parent'].includes(derivedReplaceTopWindow)
      ? derivedReplaceTopWindow
      : undefined;

  return async (signUp?: boolean) => {
    if (signUp) {
      signUpRef.current = signUp;
    }

    if (isAuthAfterCommunitySSO) {
      replace(query.communitySSOBackURL as string);
      return;
    }

    const finalRedirectURL =
      signUpRef.current &&
      !isAuthFromDemo &&
      !isAuthFromJoinWorkspace &&
      !isAuthFromJoinProject
        ? CATALOG_URL
        : redirectURL;

    if (safeReplaceTopWindow) {
      return replaceParentWindowLocation(
        finalRedirectURL,
        safeReplaceTopWindow === 'parent' ? window.parent : undefined
      );
    }

    refetchUser(); // @TODO check does it need invalidate?
    replace(finalRedirectURL);
    clientMessage.reconnect();
  };
}
